import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <i className="fa fa-line-chart"/>
                    Biro Jasa W!
                </a>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a href="#about" className="nav-link smoothScroll">Tentang Kami</a>
                        </li>
                        <li className="nav-item">
                            <a href="#project" className="nav-link smoothScroll">Layanan</a>
                        </li>
                        <li className="nav-item">
                            <a href="#testimonial" className="nav-link">Testimoni</a>
                        </li>
                        <li className="nav-item">
                            <a href="/login" className="nav-link contact">Masuk / Bergabung</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Header;
