import Header from "../components/Header";
import Footer from "../components/Footer";

const Tracking = () => {
    return (
        <>
            <Header/>
            <div>Hello Tracking!</div>
            <Footer/>
        </>

    )
}

export default Tracking;
