import { Link } from "react-router-dom";

const Footer = () => {

    return (
        <footer className="site-footer">
            <div className="container">
                <div className="row">

                    <div className="col-lg-5 mx-lg-auto col-md-8 col-10">
                        <h1 className="text-white" data-aos="fade-up" data-aos-delay="100">
                            Kami memudahkan urusan <strong>kendaraan Anda!</strong>
                        </h1>
                    </div>

                    <div className="col-lg-3 col-md-6 col-12" data-aos="fade-up" data-aos-delay="200">
                        <h4 className="my-4">Contact Info</h4>

                        <p className="mb-1">
                            <a href="https://wa.me/62818208377?text=Halo, saya ingin berkonsultasi">
                                <i className="fa fa-phone mr-2 footer-icon"/>
                                +62 818-208-377 (WhatsApp)
                            </a>
                        </p>

                        <p>
                            <a href="mailto:doubleubiro@gmail.com?subject=Konsultasi Kendaraan&body=Halo, saya ingin berkonsultasi">
                                <i className="fa fa-envelope mr-2 footer-icon"/>
                                doubleubiro@gmail.com
                            </a>
                        </p>
                    </div>

                    <div className="col-lg-3 col-md-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <h4 className="my-4">Kantor Kami</h4>

                        <p className="mb-1">
                            <i className="fa fa-home mr-2 footer-icon"/>
                            Jl. Terusan Pasirkoja No.87, Babakan Tarogong, Kec. Bojongloa Kaler, Kota Bandung, Jawa Barat 40232
                        </p>
                    </div>

                    <div className="col-lg-4 mx-lg-auto text-center col-md-8 col-12" data-aos="fade-up" data-aos-delay="400">
                        <p className="copyright-text">Copyright &copy; 2023 Biro Jasa W!</p>
                    </div>

                    <div className="col-lg-4 mx-lg-auto col-md-6 col-12" data-aos="fade-up" data-aos-delay="500">

                        <ul className="footer-link">
                            <li><a href="#">Syarat dan ketentuan</a></li>
                            <li><a href="#">Bergabung sebagai mitra dealer</a></li>
                            <li><a href="/privacy">Privacy policy</a></li>
                        </ul>
                    </div>

                    <div className="col-lg-3 mx-lg-auto col-md-6 col-12" data-aos="fade-up" data-aos-delay="600">
                        <ul className="social-icon">
                            <li><a href="#" className="fa fa-instagram"/></li>
                            <li><a href="#" className="fa fa-twitter"/></li>
                            <li><a href="#" className="fa fa-dribbble"/></li>
                            <li><a href="#" className="fa fa-behance"/></li>
                        </ul>
                    </div>

                </div>
            </div>
        </footer>
    )

};

export default Footer;
