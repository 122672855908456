import Header from "../components/Header";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

const Login = () => {
    return (
        <>
            <Header/>

            <div className="container">
                <div className="row justify-content-center mt-5 mb-5">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header bg-primary text-white text-center">
                                <h3>Masuk ke Biro Jasa W!</h3>
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" className="form-control" id="email"
                                               placeholder="Enter your email"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <input type="password" className="form-control" id="password"
                                               placeholder="Enter your password"/>
                                    </div>
                                    <button className="btn btn-primary btn-block">Login</button>
                                </form>
                            </div>
                            <div className="card-footer text-center">
                                <p>Don't have an account? <Link to="/register">Register here.</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>

    )
}

export default Login;
