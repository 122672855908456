import office from '../digital_trend/images/office.png';
import newsletter from '../digital_trend/images/newsletter.png';

const About = () => {

    return (
        <section className="about section-padding pb-0" id="about">
            <div className="container">
                <div className="row">

                    <div className="col-lg-7 mx-auto col-md-10 col-12">
                        <div className="about-info">

                            <h2 className="mb-4" data-aos="fade-up">Urusan STNK, BPKB, dan lain-lain jadi mudah dengan <strong>Biro Jasa W!</strong></h2>

                            <p className="mb-4" data-aos="fade-up">
                                Selamat datang di Biro Jasa W! Kami adalah solusi terbaik untuk Anda yang ingin mengurus perpanjangan STNK, BPKB, dan layanan lainnya dengan cepat dan mudah.
                                Kami menyediakan layanan yang dapat membantu Anda menghemat waktu dan tenaga dalam mengurus perpanjangan dokumen kendaraan Anda.
                            </p>
                            <p className="mb-4" data-aos="fade-up">
                                Dengan pengalaman dan keahlian yang kami miliki, kami berkomitmen untuk memberikan pelayanan yang terbaik kepada pelanggan.
                                Kami memiliki tim profesional yang siap membantu dan memberikan solusi terbaik untuk setiap kebutuhan Anda.
                            </p>
                            <p className="mb-4" data-aos="fade-up">
                                Kami juga memberikan layanan yang aman dan terpercaya dengan harga yang kompetitif.
                                Anda dapat mempercayakan kebutuhan perpanjangan dokumen kendaraan Anda pada kami dengan tenang.
                            </p>
                            <p className="mb-4" data-aos="fade-up">
                                Mari bergabung bersama Biro Jasa W dan nikmati kemudahan dalam mengurus perpanjangan dokumen kendaraan Anda.
                                Terima kasih atas kepercayaan Anda pada kami!
                            </p>
                        </div>

                        <div className="about-image" data-aos="fade-up" data-aos-delay="200">
                            <img src={office} className="img-fluid" alt="office"/>
                        </div>

                    </div>

                </div>
            </div>
        </section>
    )
}

export default About;
