import working_girl from '../digital_trend/images/working-girl.png';

const Hero = () => {

    return (
        <section className="hero hero-bg d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-10 col-12 d-flex flex-column justify-content-center align-items-center">
                        <div className="hero-text">
                            <h1 className="text-white" data-aos="fade-up">
                                Kami adalah Biro Jasa Kendaraan terbesar di Bandung!
                            </h1>
                        </div>
                    </div>

                    <div className="col-lg-6 col-12">
                        <div className="hero-image" data-aos="fade-up" data-aos-delay="300">
                            <img src={working_girl} className="img-fluid" alt="banner lokasi"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Hero;
