import female_avatar from '../digital_trend/images/female-avatar.png';

const Testimonial = () => {

    return (
        <section className="testimonial section-padding" id="testimonial">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-5 col-12">
                        <div className="contact-image" data-aos="fade-up">
                            <img src={female_avatar} className="img-fluid" alt="website"/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-7 col-12">
                        <h4 className="my-5 pt-3" data-aos="fade-up" data-aos-delay="100">Testimonials</h4>
                        <div className="quote" data-aos="fade-up" data-aos-delay="200"/>

                        <h2 className="mb-4" data-aos="fade-up" data-aos-delay="300">
                            Dengan tiga layanan utama ini, Biro Jasa W hadir untuk memberikan solusi yang mudah dan efisien dalam mengurus dokumen kendaraan bermotor Anda.
                            Kami memberikan layanan yang aman dan terpercaya dengan harga yang kompetitif.
                            Dapatkan layanan terbaik dari Biro Jasa W untuk menghemat waktu dan tenaga Anda.
                        </h2>

                        <p data-aos="fade-up" data-aos-delay="400">
                            <strong>User</strong>
                            <span className="mx-1">/</span>
                            <small>Nama User</small>
                        </p>
                    </div>

                </div>
            </div>
        </section>
    )

}

export default Testimonial;
