import project_image01 from '../digital_trend/images/project/project-image01.jpg';
import project_image02 from '../digital_trend/images/project/project-image02.jpg';
import project_image03 from '../digital_trend/images/project/project-image03.jpg';

const Project = () => {

    return (
        <section className="project section-padding" id="project">
            <div className="container-fluid">
                <div className="row">

                    <div className="col-lg-12 col-12">

                        <h2 className="mb-5 text-center" data-aos="fade-up">
                            Layanan kami di <strong>Biro Jasa W!</strong>
                        </h2>

                        <div className="owl-carousel owl-theme" id="project-slide">
                            <div className="item project-wrapper" data-aos="fade-up" data-aos-delay="100">
                                <img src={project_image01} className="img-fluid"/>

                                <div className="project-info">
                                    <p>Mengurus OTR kendaraan bermotor baru</p>
                                    <small>
                                        Layanan dari Biro Jasa W! untuk dealer rekanan adalah membantu Anda dalam mengurus dokumen OTR kendaraan bermotor baru.
                                        Kami memahami bahwa mengurus dokumen kendaraan baru bisa menjadi tugas yang melelahkan dan memakan waktu.
                                        Dengan layanan kami, kami akan membantu Anda dalam mengurus dokumen kendaraan bermotor baru dengan cepat dan mudah.
                                        Tim profesional kami akan membantu Anda mulai dari pengisian formulir,
                                        mengurus persyaratan hingga mendapatkan dokumen kendaraan bermotor baru yang sah dan resmi.
                                    </small>
                                </div>
                            </div>

                            <div className="item project-wrapper" data-aos="fade-up">
                                <img src={project_image02} className="img-fluid"/>

                                <div className="project-info">
                                    <p>Mengurus OTR kendaraan bermotor baru</p>
                                    <small>
                                        Layanan kedua dari Biro Jasa W adalah membantu Anda dalam mengurus perpanjangan surat-surat seperti STNK, BPKB, KIR, balik nama, dan lain sebagainya.
                                        Kami memahami betapa pentingnya memiliki dokumen kendaraan yang valid dan up-to-date.
                                        Oleh karena itu, kami akan membantu Anda dalam mengurus perpanjangan dokumen kendaraan dengan cepat dan mudah tanpa perlu mengalami kerumitan dan antrian yang panjang di kantor SAMSAT.
                                        Tim profesional kami akan membantu Anda dalam mengumpulkan dokumen yang diperlukan dan mengurus perpanjangan dokumen kendaraan Anda dengan cepat dan tepat waktu.
                                    </small>
                                </div>
                            </div>

                            <div className="item project-wrapper" data-aos="fade-up">
                                <img src={project_image03} className="img-fluid"/>

                                <div className="project-info">
                                    <p>Mengurus kehilangan surat-surat kendaraan bermotor</p>
                                    <small>
                                        Layanan ketiga dari Biro Jasa W adalah membantu Anda dalam mengurus dokumen kehilangan surat kendaraan bermotor Anda.
                                        Kehilangan surat-surat kendaraan bermotor bisa menjadi masalah besar dan membuat Anda tidak dapat mengoperasikan kendaraan Anda.
                                        Dengan layanan kami, kami akan membantu Anda dalam mengurus kehilangan surat kendaraan bermotor dengan cepat dan mudah.
                                        Tim profesional kami akan membantu Anda dalam mengurus penggantian dokumen yang hilang dan memberikan solusi terbaik untuk memulihkan dokumen kendaraan Anda dengan cepat dan tepat waktu.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Project;
