import Header from "../components/Header";
import Hero from "../components/Hero";
import About from "../components/About";
import Project from "../components/Project";
import Testimonial from "../components/Testimonial";
import Footer from "../components/Footer";

const Landing = () => {
    return (
        <>
            <Header/>
            <Hero/>
            <About/>
            <Project/>
            <Testimonial/>
            <Footer/>
        </>

    )
}

export default Landing;
