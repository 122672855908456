import Header from "../components/Header";
import Footer from "../components/Footer";

const Accounts = () => {
    return (
        <>
            <Header/>
            <div>Hello Accounts!</div>
            <Footer/>
        </>

    )
}

export default Accounts;
