import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

const Register = () => {

    const [formData, setFormData] = useState({
        fullName: "",
        dateOfBirth: "",
        email: "",
        phoneNumber: "",
        address: "",
        password: "",
        repeatPassword: "",
    });

    const [passwordError, setPasswordError] = useState("");

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!isValidPassword(formData.password)) {
            setPasswordError("Password must contain at least 6 characters with a combination of letters and numbers");
            return;
        }

        // TODO: handle form submission
    };

    const isValidPassword = (password) => {
        const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
        return regex.test(password);
    };

    return (
        <>
            <Header/>

            <div className="container">
                <div className="row justify-content-center mt-5 mb-5">
                    <div className="col-md-8 col-lg-6">
                        <div className="card">
                            <div className="card-header bg-primary text-white text-center">
                                <h3>Registrasi Akun baru</h3>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="fullName">Full Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="fullName"
                                            name="fullName"
                                            value={formData.fullName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="dateOfBirth">Date of Birth</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="dateOfBirth"
                                            name="dateOfBirth"
                                            value={formData.dateOfBirth}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phoneNumber">Phone Number</label>
                                        <input
                                            type="tel"
                                            className="form-control"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            value={formData.phoneNumber}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="address">Address</label>
                                        <textarea
                                            className="form-control"
                                            id="address"
                                            name="address"
                                            value={formData.address}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="repeatPassword">Repeat Password</label>
                                        <input type="password" className="form-control" id="repeatPassword" name="repeatPassword" value={formData.repeatPassword} onChange={handleChange} placeholder="Repeat your password" />
                                    </div>
                                    {passwordError && <div className="alert alert-danger">{passwordError}</div>}
                                    <button type="submit" className="btn btn-primary btn-block">Register</button>
                                </form>
                            </div>
                            <div className="card-footer text-center">
                                <p>Already have an account? <Link to="/login">Login here.</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>

    )
}

export default Register;
