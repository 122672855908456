import Header from "../components/Header";
import Footer from "../components/Footer";

const Login = () => {
    return (
        <>
            <Header/>

            <div className="container mb-4 mt-4">
                <h3>Kebijakan Privasi</h3>
                <p>
                    Biro Jasa W menghormati privasi Anda sebagai pengguna website kami. Kebijakan privasi ini menjelaskan bagaimana kami mengumpulkan, menggunakan, dan melindungi informasi yang Anda berikan kepada kami saat menggunakan website kami.
                </p>
                <h5>Informasi yang Kami Kumpulkan</h5>
                <p>
                    Kami dapat mengumpulkan informasi pribadi yang Anda berikan kepada kami, seperti nama, alamat email, dan nomor telepon saat Anda menghubungi kami melalui formulir kontak atau email. Kami juga dapat mengumpulkan informasi non-pribadi, seperti alamat IP, jenis browser, dan sistem operasi yang digunakan ketika Anda mengakses website kami.
                </p>
                <h5>Bagaimana Kami Menggunakan Informasi yang Kami Kumpulkan</h5>
                <p>
                    Kami dapat menggunakan informasi yang kami kumpulkan untuk:
                    <ul>
                        <li>
                            Menanggapi permintaan Anda untuk layanan atau dukungan
                        </li>
                        <li>
                            Mengirimkan informasi tentang layanan atau promosi yang mungkin menarik bagi Anda
                        </li>
                        <li>
                            Meningkatkan pengalaman pengguna pada website kami
                        </li>
                        <li>
                            Mengirimkan survei atau permintaan umpan balik tentang layanan kami
                        </li>
                    </ul>
                    Kami tidak akan membagikan atau menjual informasi pribadi Anda kepada pihak ketiga tanpa izin Anda, kecuali jika diwajibkan oleh hukum.
                </p>
                <h5>Bagaimana Kami Melindungi Informasi Anda</h5>
                <p>
                    Kami di Biro Jasa W sangat peduli dan menghargai privasi Anda sebagai pengguna website kami. Kami memahami bahwa informasi pribadi dan data Anda sangatlah penting, dan kami mengambil langkah-langkah untuk melindungi informasi tersebut dengan serius.
                </p>
                <p>
                    Kami hanya mengumpulkan informasi yang diperlukan untuk memberikan layanan kami kepada Anda. Informasi pribadi yang kami kumpulkan hanya digunakan untuk tujuan yang telah dijelaskan dalam Kebijakan Privasi kami, dan kami tidak akan membagikan informasi tersebut dengan pihak ketiga tanpa izin Anda.
                </p>
                <p>
                    Kami menggunakan standar industri untuk melindungi informasi Anda dari akses yang tidak sah atau penggunaan yang salah. Kami mengimplementasikan protokol keamanan seperti enkripsi SSL dan mengambil langkah-langkah lain yang wajar untuk memastikan bahwa informasi sensitif yang Anda berikan kepada kami tetap aman.
                </p>
                <p>
                    Anda berhak untuk mengetahui informasi yang kami miliki tentang Anda dan meminta agar informasi tersebut diperbaiki atau dihapus jika diperlukan. Jika Anda memiliki pertanyaan atau komentar tentang kebijakan privasi kami, jangan ragu untuk menghubungi kami melalui formulir kontak di website kami.
                </p>
                <h5>Kebijakan Privasi Ini Berlaku</h5>
                <p>
                    Kebijakan privasi ini berlaku untuk penggunaan website kami.
                    Kami dapat mengubah kebijakan ini dari waktu ke waktu dan akan memposting perubahan pada halaman ini.
                </p>
                <h5>Kontak Kami</h5>
                <p>
                    Jika Anda memiliki pertanyaan atau komentar tentang kebijakan privasi kami, silakan hubungi kami melalui formulir kontak di website kami.
                </p>
            </div>

            <Footer/>
        </>

    )
}

export default Login;
