const NotFound = () => {
    return (
        <div className="container">
            <h1 className="">404</h1>
            <h2 className="">Oops! Halaman tidak ditemukan.</h2>
            <p className="">Maaf, halaman yang Anda cari tidak dapat ditemukan.</p>
        </div>
    )
}

export default NotFound;
