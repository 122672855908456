import {Router, Route, Switch} from "react-router-dom";
import history from './History';

//stylesheet
import "./App.css";

//pages
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Accounts from "./pages/Accounts";
import Tracking from "./pages/Tracking";
import NotFound from "./pages/NotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const App = () => {
    return (
        <Router history={history}>
            <Switch>
                <Route path="/login" exact component={Login}/>
                <Route path="/register" exact component={Register}/>
                <Route path="/accounts" exact component={Accounts}/>
                <Route path="/tracking" exact component={Tracking}/>
                <Route path="/privacy" exact component={PrivacyPolicy}/>
                <Route path="/" exact component={Landing}/>
                <Route component={NotFound}/>
            </Switch>
        </Router>
    );
}

export default App;
